import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import MapComponent from "../../components/MapComponent";
import { tokens } from "../../theme";
import "./Map.css";

const Map = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Set the CSS variable dynamically
    document.documentElement.style.setProperty('--dynamic-border-color', colors.grey[100]);

    return (
        <Box className="mapContainer">
            <Header className="header" title="SENSOR MAP" subtitle="IsCleanAir sensor locations" />
            <Box className="mapBox">
                <MapComponent />
            </Box>
        </Box>
    );
};

export default Map;