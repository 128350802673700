import React from 'react';
import { connect } from 'react-redux';

const PrivateComp = ({ isAuthenticated, component: Component, ...rest }) => {
  console.log("PrivateComp isAuthenticated: " + isAuthenticated)

  return isAuthenticated ? <Component {...rest} /> : null;
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(PrivateComp);
