import {Box, Typography, useTheme} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockSensorList } from "../../data/mockData";
import Header from "../../components/Header";
import Switch from '@mui/material/Switch';
import React, { useState, useEffect, useContext } from 'react';
import ApiService from "../../services/ApiService";
import { GlobalConstantsContext } from '../../context/GlobalConstantsContext';
import { setSessionValue, getSessionValue } from '../../utils/secureCookieStorage';

const Sensor = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const [isLoading, setIsLoading] = useState(true);
    const { yourConstantData, updateConstantData } = useContext(GlobalConstantsContext);
    const user = JSON.parse(getSessionValue('user'));
    console.log("Sensor user " + user);
    const user_id = user.id;
    
    useEffect(() => {
        if (yourConstantData.length === 0) {
            console.log("Load data through API")
            ApiService.fetchData(`${process.env.REACT_APP_API_URL}/api/getUserDataView/${user_id}/`)
                .then((data) => {
                    updateConstantData(data.sensor_data);
                    setIsLoading(false);
                })    
                .catch((error) => {
                // Handle the error, e.g., show an error message    
                });
        } else {
            console.log(yourConstantData)
            console.log("Data already available")
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Function to update SensorPreferences
    const updateSensorPreferences = async (row) => {
        console.log("Updating data")
        const url = `${process.env.REACT_APP_API_URL}/api/update_sensor_preferences/${row.id}/${user_id}/`;
        console.log(url)
        const data = {
            show_on_dashboard: row.show_on_dashboard,
            show_on_map: row.show_on_map,
            sensor_id: row.sensor_id,
            user_id: row.user_id,
        };

        try {
            const updatedData = await ApiService.updatePreferenceData(url, data);
            console.log('Updated data:', updatedData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    }
    
    function handlePropertyChange(e, rowId, propertyName) {
        const updatedData = yourConstantData.map((row) => {
            if (row.id === rowId) {
                console.log(row.id)
                // Toggle the specified property when the switch is clicked
                row[propertyName] = row[propertyName] === true ? false : true;
                // Update the database or perform any other necessary actions here
                console.log(row[propertyName])
                updateSensorPreferences(row)
                return row;
            }
            return row;
        });
        updateConstantData(updatedData);
    }

    const columns = [
        // {
        //     field: "id",
        //     headerName: "ID"
        // },
        {
            field: "sensor_model",
            headerName: "Sensor Name",
            flex: 0.5,
            cellClassName: "name-column--cell"
        },
        {
            field: "sensor_manufacturer",
            headerName: "Manufacturer",
        },
        {
            field: "sensor_latitude",
            headerName: "Lat",
            type: "number",
            headerAlign: "left",
            align: "left"
        },
        {
            field: "sensor_longitude",
            headerName: "Long",
            type: "number",
            headerAlign: "left",
            align: "left"
        },
        {
            field: "sensor_id",
            headerName: "Sensor ID",
            type: "number",
            headerAlign: "left",
            align: "left"
        },
        {
            field: "time_zone",
            headerName: "Time Zone",
            type: "text",
            headerAlign: "left",
            align: "left"
        },
        {
            field: "sensor_description",
            headerName: "Sensor Description",
            flex: 0.5,
            type: "text",
            headerAlign: "left",
            align: "left"
        },
        {
            field: "showOnMap",
            headerName: "Show on Map",
            flex: 0.5,
            type: "boolean",
            renderCell: ({ row }) => {
                return (
                    <div>
                        <Switch
                            onChange={(e) => handlePropertyChange(e, row.id, "show_on_map")}
                            checked={row.show_on_map}
                            color="secondary"
                        />
                    </div>
                );
            },
        },
        {
            field: "showOnDashboard",
            headerName: "Show on dashboard",
            flex: 0.5,
            type: "boolean",
            renderCell: ({ row }) => {
                return (
                    <div>
                        <Switch
                            onChange={(e) => handlePropertyChange(e, row.id, "show_on_dashboard")}
                            checked={row.show_on_dashboard}
                            color="secondary"
                        />
                    </div>
                );
            },
        },
    ];

    return(
        <Box m="20px">
            <Header title="Sensors" subtitle="Manage list of available sensors" />
            <Box m="40px 0 0 0" height="75vh" sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colours.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colours.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colours.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colours.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colours.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colours.grey[100]} !important`,
                },
            }}>
                <DataGrid
            
                    rows={yourConstantData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    )


}

export default Sensor;