// GlobalConstantsContext.js
import React, { createContext, useState, useContext } from 'react';

const GlobalConstantsContext = createContext();

const GlobalConstantsProvider = ({ children }) => {
  const [yourConstantData, setYourConstantData] = useState([]);

  const updateConstantData = (newData) => {
    setYourConstantData(newData);
  };

  return (
    <GlobalConstantsContext.Provider value={{ yourConstantData, updateConstantData }}>
      {children}
    </GlobalConstantsContext.Provider>
  );
};

export { GlobalConstantsProvider, GlobalConstantsContext };