import {Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/TopBar";
import Sidebar from "./scenes/global/SideBar";
import Dashboard from "./scenes/dashboard";
import Sensor from "./scenes/sensor";
import Map from "./scenes/map";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColourModeContext, useMode } from "./theme";
import { GlobalConstantsProvider } from "./context/GlobalConstantsContext";
import Layout from './hocs/Layout';
import Login from './scenes/authentication/Login';
import ResetPassword from "./scenes/authentication/ResetPassword";
import ResetPasswordConfirm from "./scenes/authentication/ResetPasswordConfirm";
import { Provider } from "react-redux"; 
import store from './store';
import PrivateRoute from './hocs/PrivateRoute'
import PrivateComp from './hocs/PrivateComp'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import secureLocalStorage from "react-secure-storage";
//import { syncSessionStorageAcrossTabs } from './utils/secureCookieStorage';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
      const handleBeforeUnload = (event) => {
        // Perform any action you need before the app closes.
        console.log("Application is closing");
        // secureLocalStorage.removeItem('access');
        // secureLocalStorage.removeItem('refresh');
        // secureLocalStorage.removeItem('user');
        // secureLocalStorage.clear();
        // If you want to prompt the user with a confirmation dialog:
        // event.preventDefault(); // This is necessary for some browsers.
        // event.returnValue = ''; // A legacy way to show the confirmation dialog.
      };

      //syncSessionStorageAcrossTabs();

      // Adding the event listener for beforeunload
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
  }, []);
  
  return (
    <Provider store={store}>
      <ColourModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalConstantsProvider>
            <div className="app">
              <Layout>
                <PrivateComp
                  component={Sidebar}
                  isSidebar={isSidebar}
                />
                <main className="content">
                  <PrivateComp
                    component={Topbar}
                    isSidebar={isSidebar}
                  />  
                  <PrivateRoute
                    path="/"
                    element={<Dashboard/>}
                  />
                  <PrivateRoute
                    path="/sensor"
                    element={<Sensor/>}
                  />
                  <PrivateRoute
                    path="/map"
                    element={<Map/>}
                  />
                  <Routes>
                    <Route
                      path="/login"
                      element={<Login onLogin={() => navigate('/')} />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPassword onLogin={() => navigate('/login')} />}
                    />
                    <Route
                      path="/password/reset/confirm/:uid/:token"
                      element={<ResetPasswordConfirm onLogin={() => navigate('/login')} />}
                    />
                  </Routes>
                </main>
              </Layout>
            </div>
          </GlobalConstantsProvider>
        </ThemeProvider>
      </ColourModeContext.Provider>
    </Provider>
  );

  
}

export default App;
