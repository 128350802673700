import React from 'react';
import { Box, Button, TextField, Typography, Link, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { tokens } from "../../theme";

const Login = ({login, isAuthenticated, onLogin}) => {
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);

    const userSchema = yup.object().shape({
        email: yup.string().email('Invalid email').required('Required'),
        password: yup.string().required('Required'),
    });

    const initialValues = {
        email: '',
        password: '',
    };

    if (isAuthenticated) {
        onLogin();
    }

    const handleFormSubmit = async (values) => {
        try {
            // Add logic to handle form submission
            await login(values.email, values.password);

            // Call the onLogin prop to trigger the redirection
            onLogin();
        } catch (error) {
            // Handle login error
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" height="100vh">
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px'}}>
                    <Header title="User Login" subtitle="Login to your account" />
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={userSchema}
                        >
                            {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            }) => (
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h4" gutterBottom>
                                User Login
                                </Typography>
                                <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ mb: 2 }}
                                />
                                <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ mb: 2 }}
                                />
                                <Box display="flex" justifyContent="end" mt={2}>
                                <Button type="submit" color="primary" variant="contained">
                                    Login
                                </Button>
                                </Box>
                                <Typography color="#fff" variant="h5" mt={2}>
                                    <Link color="#fff" href="/reset-password">
                                        Forgot password? Reset it here.
                                    </Link>
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

// export default LoginTwo;
export default connect(mapStateToProps, { login })(Login);
