// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MapComponent.css */
.mapComponentContainer {
    height: 75vh;
    /* Sets the height of the map */
    width: 100%;
    /* Ensures the map takes the full width of the container */
}`, "",{"version":3,"sources":["webpack://./src/components/MapComponent.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,YAAY;IACZ,+BAA+B;IAC/B,WAAW;IACX,0DAA0D;AAC9D","sourcesContent":["/* MapComponent.css */\n.mapComponentContainer {\n    height: 75vh;\n    /* Sets the height of the map */\n    width: 100%;\n    /* Ensures the map takes the full width of the container */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
