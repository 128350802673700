import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useState, useContext, useEffect } from "react";
import { Icon } from "leaflet";
import L from "leaflet";
import { GlobalConstantsContext } from '../context/GlobalConstantsContext';
import ApiService from "../services/ApiService";
import { setSessionValue, getSessionValue } from '../utils/secureCookieStorage';
import "./MapComponent.css";  // Import the CSS file

const MapComponent = () => {
    const DEFAULT_CENTER = [51.505, -0.09];
    const DEFAULT_ZOOM = 13;
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const { yourConstantData, updateConstantData } = useContext(GlobalConstantsContext);
    const [isLoading, setIsLoading] = useState(true);
    let markers = [];

    const markerIcon = new Icon({
        iconUrl: "../../assets/marker-icon.png",
        iconSize: [30, 38],
    });

    useEffect(() => {
        if (yourConstantData.length === 0) {
            console.log("Load data through API");
            const user = JSON.parse(getSessionValue('user'));
            const user_id = user.id;
            ApiService.fetchData(`${process.env.REACT_APP_API_URL}/api/getUserDataView/${user_id}/`)
                .then((data) => {
                    updateConstantData(data.sensor_data);
                    setIsLoading(false);
                })    
                .catch((error) => {
                    // Handle the error
                });
        } else {
            console.log("Data already available");
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (yourConstantData.length > 0) {
        markers = yourConstantData
            .filter(sensor => sensor.show_on_map === true)
            .map(sensor => ({
                sensorId: sensor.sensor_id,
                latitude: sensor.sensor_latitude,
                longitude: sensor.sensor_longitude,
                manufacturer: sensor.sensor_manufacturer,
                model: sensor.sensor_model,
            }));

        const markerCoordinates = markers.map(marker => [marker.latitude, marker.longitude]);
        var group = L.featureGroup();
        markerCoordinates.forEach(coords => {
            var marker = L.marker(coords).addTo(group);
            marker.bindPopup("This is a marker!");
        });
    }

    return (
        <MapContainer
            className="mapComponentContainer" // Apply the CSS class here
            bounds={yourConstantData.length > 0 && markers.length > 0 ? group.getBounds() : null}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {yourConstantData.length > 0 && markers.length > 0 ? (
                markers.map(marker => (
                    <Marker
                        key={marker.sensorId}
                        position={[marker.latitude, marker.longitude]}
                        icon={markerIcon}
                    >
                        <Popup>
                            <div>
                                <strong>Model:</strong> {marker.model}<br />
                                <strong>Manufacturer:</strong> {marker.manufacturer}<br />
                                <strong>Sensor ID:</strong> {marker.sensorId}<br />
                                <strong>Latitude:</strong> {marker.latitude}<br />
                                <strong>Longitude:</strong> {marker.longitude}
                            </div>
                        </Popup>
                    </Marker>
                ))
            ) : (
                <MapContainer center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
            )}
        </MapContainer>
    );
};

export default MapComponent;