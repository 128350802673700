// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Map.css */
.mapContainer {
    margin: 20px;
}

/* .header {
    
} */

.mapBox {
    height: 75.3vh;
    border: 1px solid var(--dynamic-border-color);
    /* Use a CSS variable */
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/scenes/map/Map.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,YAAY;AAChB;;AAEA;;GAEG;;AAEH;IACI,cAAc;IACd,6CAA6C;IAC7C,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":["/* Map.css */\n.mapContainer {\n    margin: 20px;\n}\n\n/* .header {\n    \n} */\n\n.mapBox {\n    height: 75.3vh;\n    border: 1px solid var(--dynamic-border-color);\n    /* Use a CSS variable */\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
