import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY;

/**
 * Encrypts the value and stores it in a cookie.
 * @param {string} key - The name of the cookie.
 * @param {string} value - The value to store.
 * @param {number} [expires=7] - The number of days until the cookie expires.
 */
export const setCookieValue = (key, value, expires = 7) => {
  if (secretKey) {
    const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString();
    Cookies.set(key, encryptedValue, { expires });
  } else {
    console.error('Secret key is not defined.');
  }
};

/**
 * Retrieves a value from a cookie and decrypts it.
 * @param {string} key - The name of the cookie.
 * @returns {string|null} The decrypted value or null if not found.
 */
export const getCookieValue = (key) => {
  if (secretKey) {
    const encryptedValue = Cookies.get(key);
    if (encryptedValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  } else {
    console.error('Secret key is not defined.');
  }
  return null;
};

/**
 * Encrypts the value and stores it in session storage, 
 * then syncs the data across tabs via localStorage.
 * @param {string} key - The name of the session storage item.
 * @param {string} value - The value to store.
 */
export const setSessionValue = (key, value) => {
  if (secretKey) {
    const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString();
    sessionStorage.setItem(key, encryptedValue);

    // Sync across tabs
    //localStorage.setItem(`sync_${key}`, encryptedValue);

    // Remove the sync item from localStorage after the sync
    //localStorage.removeItem(`sync_${key}`);
  } else {
    console.error('Secret key is not defined.');
  }
};

/**
 * Retrieves a value from session storage and decrypts it.
 * @param {string} key - The name of the session storage item.
 * @returns {string|null} The decrypted value or null if not found.
 */
export const getSessionValue = (key) => {
  if (secretKey) {
    const encryptedValue = sessionStorage.getItem(key);
    if (encryptedValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  } else {
    console.error('Secret key is not defined.');
  }
  return null;
};

/**
 * Sync session storage across tabs by listening to the storage event.
 */
// export const syncSessionStorageAcrossTabs = () => {
//   window.addEventListener('storage', (event) => {
//     if (event.key && event.key.startsWith('sync_')) {
//       const key = event.key.replace('sync_', '');
//       const encryptedValue = event.newValue;

//       if (encryptedValue) {
//         sessionStorage.setItem(key, encryptedValue);
//       } else {
//         sessionStorage.removeItem(key);
//       }
//     }
//   });
// };
