// PrivateRoute.jsx
import React from 'react';
import { Routes , Route, Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ element, path ,isAuthenticated }) => {
  const location = useLocation();
  console.log("PrivateRoute isAuthenticated : " + isAuthenticated)
  console.log("location.pathname : " + location.pathname)
  

  if (!isAuthenticated || isAuthenticated==null) {
    if (location.pathname === '/reset-password') {
      return <Navigate to="/reset-password" />;
    } else if (location.pathname.includes("password/reset/confirm/")) {
      return <Navigate to={location.pathname} />;
    }
  }

  return isAuthenticated !== null ? (
    isAuthenticated ? (
      <Routes>
        <Route path={path} element={element} />
      </Routes>
    ) : (
      <Navigate to="/login" />
    )
  ) : null;

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(PrivateRoute);
