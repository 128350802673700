// ApiService.js
import axios from 'axios';
import { setCookieValue, getCookieValue } from '../utils/secureCookieStorage';

const ApiService = {
  fetchData: async (url) => {
    if (getCookieValue('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${getCookieValue('access')}`,
          'Accept': 'application/json'
        }
      };
      console.log(getCookieValue('access'));
      try {
        const response = await axios.get(url, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching data from the API:', error);
        throw error;
      }
    }
  },
  updatePreferenceData: async (url, data) => {
    if (getCookieValue('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${getCookieValue('access')}`,
          'Accept': 'application/json'
        }
      };
      console.log(getCookieValue('access'));
      try {
        const response = await axios.put(url, data, config); // You can use put() for updating data
        return response.data;
      } catch (error) {
        console.error('Error posting data to the API:', error);
        throw error;
      }
    }
  },
};

export default ApiService;