import React,{useState} from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../../actions/auth';
import { Redirect, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const ResetPasswordConfirm = ({ reset_password_confirm, isAuthenticated, onLogin }) => {
    const [requestSent, setRequestSent] = useState(false);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const { uid, token } = useParams(); 
    const userSchema = yup.object().shape({
        new_password: yup.string().required('Password is required'),
        re_new_password: yup.string()
            .oneOf([yup.ref('new_password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const initialValues = {
        new_password: '',
        re_new_password: ''
    };

    if (isAuthenticated) {
        onLogin();
    }
    
    if (requestSent) {
        onLogin();
        setRequestSent(true)
    }

    const handleFormSubmit = async (values) => {
        try {

            // Add logic to handle form submission
            await reset_password_confirm(uid, token, values.new_password, values.re_new_password);

            // Call the onLogin prop to trigger the redirection
           onLogin();
        } catch (error) {
            // Handle login error
        }
    };

return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Header title="Reset password" subtitle="Confirm new password for your account" />
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={userSchema}
                >
                            
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                            
                        <form onSubmit={handleSubmit}>
                        
                            <Typography variant="h4" gutterBottom>
                                Confirm new password
                            </Typography>
                            <TextField
                            fullWidth
                            variant="filled"
                            type="password"
                            label="New password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.new_password}
                            name="new_password"
                            error={touched.new_password && !!errors.new_password}
                            helperText={touched.new_password && errors.new_password}
                            sx={{ mb: 2 }}
                            />
                            <TextField
                            fullWidth
                            variant="filled"
                            type="password"
                            label="Confirm new password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.re_new_password}
                            name="re_new_password"
                            error={touched.re_new_password && !!errors.re_new_password}
                            helperText={touched.re_new_password && errors.re_new_password}
                            sx={{ mb: 2 }}
                            />
                        
                            <Box display="flex" justifyContent="end" mt={2}>
                                <Button type="submit" color="primary" variant="contained">
                                    Reset password
                                </Button>
                            </Box>
                            <Typography color="#fff" variant="h5" mt={2}>
                                <Link color="#fff" href="/login">
                                    Login here.
                                </Link>
                            </Typography>    
                        </form>
                    )}
                </Formik>
            </Paper>
        </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

// export default LoginTwo;
export default connect(mapStateToProps, { reset_password_confirm })(ResetPasswordConfirm);

