import React,{useState} from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { reset_password } from '../../actions/auth';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const ResetPassword = ({ reset_password, isAuthenticated, onLogin }) => {
    const [requestSent, setRequestSent] = useState(false);
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const userSchema = yup.object().shape({
        email: yup.string().email('Invalid email').required('Required')
    });

    const initialValues = {
        email: ''
    };

    if (isAuthenticated) {
        onLogin();
    }
    
    if (requestSent) {
        onLogin();
        setRequestSent(true)
    }

    const handleFormSubmit = async (values) => {
        try {
            // Add logic to handle form submission
            await reset_password(values.email);

            // Call the onLogin prop to trigger the redirection
            onLogin();
        } catch (error) {
            // Handle login error
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" height="100vh">
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                <Header title="Reset password" subtitle="Send password reset link to the following email" />
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h4" gutterBottom>
                                Input email
                                </Typography>
                                <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ mb: 2 }}
                                />
                                <Box display="flex" justifyContent="end" mt={2}>
                                <Button type="submit" color="primary" variant="contained">
                                    Reset
                                </Button>
                                </Box>
                                <Typography color="#fff" variant="h5" mt={2}>
                                    <Link color="#fff" href="/login">
                                        Login here.
                                    </Link>
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

// export default LoginTwo;
export default connect(mapStateToProps, { reset_password })(ResetPassword);
