import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColourModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
// import Popper from "popper.js";
// import ClickAwayListener from 'react-click-away-listener';
// import Paper from '@mui/material/Paper';
// import {Typography} from '@mui/material';

const Topbar = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const colourMode = useContext(ColourModeContext);

      const [anchorEl, setAnchorEl] = useState(null);

        const handleButtonClick = (event) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

    return(
        <Box display="flex" justifyContent={"space-between"} p={2}>
            {/* SEARCH BAR*/}
            <Box
                display="flex" 
                backgroundColor={colours.primary[400]} 
                borderRadius="3px"
            >
                <InputBase sx={{ml:2 , flex: 1}} placeholder="Search"/>
                <IconButton type="button" sx={{ p : 1 }}>
                    <SearchIcon/>
                </IconButton>
            </Box>
            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colourMode.toggleColourMode}>
                    {theme.palette.mode==='dark' ? (
                            <DarkModeOutlinedIcon/>
                        ) : (
                            <LightModeOutlinedIcon/>
                        )
                    }
                    
                </IconButton>
                <IconButton>
                    <NotificationsOutlinedIcon/>
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon/>
                </IconButton>
                <IconButton onClick={handleButtonClick}>
                    <PersonOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    )
};

export default Topbar;


                // <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
                // <ClickAwayListener onClickAway={handleClose}>
                //     <Paper elevation={3} sx={{ padding: 2 }}>
                //     {/* Your floating form content goes here */}
                //     <Typography variant="h6">User Profile Form</Typography>
                //     {/* Add your form elements and content here */}
                //     </Paper>
                // </ClickAwayListener>
                // </Popper>