import Header from "../../components/Header";
import LineChart  from "../../components/LineChart";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useContext } from "react";
import { ColourModeContext, tokens } from "../../theme";

const Dashboard = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard"/>
            <Box height="75vh" border={`1px solid ${colours.grey[100]}`}>
                <LineChart/>
            </Box>
        </Box>
    )
    
}

export default Dashboard;