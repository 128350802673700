import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react'


const LineChart = () => {
  const [chartData, setChartData] = useState({
    timestamps: [], // Store sensor_timestamp values
    series: [],
  })


  useEffect(() => {
    const socket = new WebSocket('wss://websocket.iscleanair.io') // Replace with your WebSocket server URL

    socket.onopen = () => {
      console.log('Connected to the WS Svr!')
      
      // JSON data to send after connection
      const message = {
        table_name: "test_sensor_two",
        sensor_id: "404CCA45175C"
      }

      console.log('message : ' + message)

      socket.send(JSON.stringify(message))
    }

    socket.addEventListener('message', (event) => {
      console.log(event)
      const data = JSON.parse(event.data)
      // Check if data is an array or a single object
      //const dataArray = Array.isArray(data) ? data : [data]
      console.log(data)
      if (Array.isArray(data)) {
        data.forEach((dataObj) => {
          const sensorTimestamp = dataObj.sensor_timestamp
          const labels = dataObj.labels
          const seriesData = dataObj.data
          // Convert to human readable time
          const utcTimestamp = new Date(sensorTimestamp)
          const day = utcTimestamp.getUTCDate()
          const month = utcTimestamp.getUTCMonth() + 1 // Months are 0-based
          const year = utcTimestamp.getUTCFullYear()
          const hour = utcTimestamp.getUTCHours()
          const minute = utcTimestamp.getUTCMinutes()
          const second = utcTimestamp.getUTCSeconds()
          // Format the date and time manually
          const formattedDateTime = `${day}/${month}/${year}, ${hour}:${minute}:${second}`
          // Update the chart data by appending the new data
          setChartData((prevData) => {
            const newSeries = labels.map((label, index) => {
              const existingSeries = prevData.series.find((s) => s.name === label)
              const newData = existingSeries
                ? existingSeries.data.concat([seriesData[index]])
                : [seriesData[index]]
              return {
                name: label,
                type: 'line',
                data: newData,
                timestamps: prevData.timestamps.concat(formattedDateTime),
              }
            })
            return {
              timestamps: prevData.timestamps.concat(formattedDateTime),
              series: newSeries,
            }
          })
        })
      }
    })

    return () => {
      socket.close()
    }
  }, [])

  const option = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      padding: 5,
      borderWidth: 1,
    },
    legend: {
      data: chartData.labels,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: chartData.timestamps.map(String), // Use timestamps as x-axis data
    },
    yAxis: {
      type: 'value',
    },
    series: chartData.series,
  }

  return (
    <div>
      <h4 id="traffic" className="card-title mb-0"></h4>
      <div className="small text-medium-emphasis"></div>
      <ReactEcharts option={option} />
    </div>
  )
}

export default LineChart;