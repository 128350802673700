import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/auth';

window.onunload = () => {
   // Clear the local storage
   window.MyStorage.clear()
}

const Layout = ({ checkAuthenticated, load_user, children }) => {

    useEffect(() => {
        checkAuthenticated();
        load_user();
    }, []);
    
    return (
        <>
            {children}
        </>
    );
};

export default connect(null, { checkAuthenticated, load_user })(Layout);
